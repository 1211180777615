.title-section {
    height: 80vh;
    width: 100vw;
    background-color: black;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    overflow: hidden;
    flex-direction: column;
    position: relative;
    line-height: 1.3;
}

.title-container {
    z-index: 1;
    margin-left: 5%;
}

.title-text {
    display: inline-block;
    font-size: 5rem;
    text-transform: uppercase;
    font-family: 'Do Hyeon', sans-serif;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4), 1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2), 1px 30px 60px rgba(16, 16, 16, 0.4);
    z-index: 1;
    width: auto !important;
    margin: 30px 0;
    letter-spacing: 10px;
}

.subtitle-text {
    display: inline-block;
    font-size: 1.3rem;
    font-family: 'Do Hyeon', sans-serif;
    color: white;
    z-index: 1;
    width: auto !important;
    margin: 10px 0;
    letter-spacing: 4px;
}

@media screen and (max-width: 900px) {
    .title-text {
        font-size: 4rem;
    }
    .subtitle-text {
        font-size: 1rem;
    }
}

.astro-img {
    position: absolute;
    width: 200px;
    right: 30px;
    bottom: 0;
    z-index: 2;
}

.scroll-div {
    width: 100%;
    position: absolute;
    bottom: 50px;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .title-text {
        font-size: 2.5rem;
    }
    .astro-img {
        width: 100px;
        right: 10px;
    }
}

@media screen and (max-width: 400px) {
    .title-container {
        margin-right: 1%;
    }
}